<template>
  <div>
    <div class="page-reward">
      <a v-waves id="rewardBtn" href="javascript:void(0);" class="page-reward-btn waves-effect waves-circle waves-light">赏</a>
    </div>
    <div id="reward" class="page-modal reward-lay animated bounceInDown">
      <a class="close" href="javascript:;"><i class="icon icon-close"></i></a>
      <h3 class="reward-title">
        <i class="icon icon-quote-left"></i>
        赠人玫瑰，手有余香
        <i class="icon icon-quote-right"></i>
      </h3>
      <div class="reward-content">

        <div class="reward-code">
          <img id="rewardCode" :src="flag ? paying_payee:wechat_payee" alt="打赏二维码">
        </div>

        <label class="reward-toggle">
          <input id="rewardToggle" type="checkbox" class="reward-toggle-check">
          <div class="reward-toggle-ctrol" @click="changeImg">
            <span class="reward-toggle-item wechat">微信</span>
            <span class="reward-toggle-label"></span>
            <span class="reward-toggle-item alipay">支付宝</span>
          </div>
        </label>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "award",
  data(){
    return{
      paying_payee:require('../../assets/images/paying_payee.png'),
      wechat_payee:require('../../assets/images/wechat_payee.png'),
      flag:false,
    }
  },
  methods:{
    changeImg(){
      this.flag = !this.flag
    }
  },
  mounted() {
    /**************赞赏窗********************/
    $('.icon-close,#mask').click(function(){
      $('#reward').removeClass('ready in');
      $('#mask').removeClass('in');
    })
    $('#rewardBtn').click(function(){
      $('#reward').addClass('ready in');
      $('#mask').addClass('in');
    })
  }
}
</script>

<style scoped>
.page-modal{
	left: 35%
}
@media screen and (max-width:480px) {
  .page-modal{
    left: 0%;
    width: 100%;
  }
}
@media screen and (max-width: 768px){
  .page-modal{
    width: 100%;
	left: 0%
  }
}
.reward-title{
   height: 52px;
 }
</style>
